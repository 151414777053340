<template>
  <div class="image-exams-section px-3" v-show="!loading && labs.length > 0">
    <v-card outlined class="box">
      <div class="pa-4">
        <v-row>
          <v-col>
            <p class="ma-0">
              <v-icon color="primary">mdi-water</v-icon>
              <span class="ml-2 font-weight-bold text-uppercase primary--text">
                Últimos Exames LABORATORIAIS
              </span>
            </p>
          </v-col>
          <v-col class="text-right">
            <v-btn small outlined color="primary" :to="'/exams?tab=labs'">
              Lista completa
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-divider />
      <LoadingContainer v-if="loading" />
      <p v-else-if="labs.length == 0">Nenhum exame encontrado.</p>
      <div v-else class="exams">
        <div v-for="(lab, index) in labs" :key="index" class="px-4 py-2">
          <div class="pa-4">
            <v-row
              no-gutters
              class="link-color"
              justify="center"
              align="center"
            >
              <v-col cols="12" sm="2">
                <div class="font-weight-medium">
                  <v-btn
                    class="mr-2"
                    icon
                    color="primary"
                    @click.prevent="toggleDetails(index)"
                  >
                    <v-icon v-if="lab.open">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-icon color="primary">mdi-calendar</v-icon>
                  {{ lab.date }}
                </div>
              </v-col>
              <v-col cols="12" sm="10">
                <v-icon color="primary" class="mr-1">mdi-account</v-icon>
                Solicitante:
                <strong>{{ lab.exams[0].doctor_requestor }}</strong>
              </v-col>
            </v-row>
            <v-row no-gutters v-show="lab.open">
              <v-col>
                <v-list two-line>
                  <v-list-item
                    v-for="(exam, index) in lab.exams"
                    :key="`lab-exam-${index}`"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ exam.name }}
                        <v-chip
                          v-if="exam.lau_isn != null && exam.lau_isn != 0"
                          small
                          dark
                          class="ml-1"
                          color="green"
                        >
                          Laudo liberado
                        </v-chip>
                        <v-chip v-else small dark class="mt-1" color="orange">
                          Laudo pendente
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon class="mb-0">
                      <v-btn
                        v-if="exam.external_report_url != null"
                        text
                        :href="exam.external_report_url"
                        class="text-capitalize font-weight-regular"
                        target="_blank"
                      >
                        <v-icon left color="primary">
                          mdi-clipboard-text
                        </v-icon>
                        <span class="link-color">Ver Laudo</span>
                      </v-btn>
                      <ReportDialog
                        v-else
                        :lau-isn="exam.lau_isn"
                        :pad-isn="exam.id"
                      >
                        <template v-slot:open="{ open }">
                          <v-btn
                            text
                            class="text-capitalize font-weight-regular"
                            @click="open"
                            :disabled="
                              exam.lau_isn == null || exam.lau_isn == 0
                            "
                          >
                            <v-icon left color="primary">
                              mdi-clipboard-text
                            </v-icon>
                            <span class="link-color">Ver Laudo</span>
                          </v-btn>
                        </template>
                      </ReportDialog>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </div>
          <v-divider v-if="index < labs.length - 1" />
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import { orderBy } from 'lodash'
import LoadingContainer from '@/components/LoadingContainer'
import ReportDialog from '@/components/ReportDialog'
export default {
  name: 'LabExamSections',
  components: { LoadingContainer, ReportDialog },
  data() {
    return {
      loading: false,
      labs: [],
    }
  },

  mounted() {
    this.getExams()
  },

  methods: {
    toggleDetails(index) {
      const lab = this.labs[index]
      lab.open = lab.open == null ? true : !lab.open
      this.$set(this.labs, index, lab)
    },

    async getExams() {
      this.loading = true
      try {
        const labExamsResponse = await Api.labExams({})
        this.labs = orderBy(
          labExamsResponse.data.orders,
          order => {
            return this.$moment(order.date, 'DD/MM/YYYY')
          },
          ['desc']
        ).slice(0, 6)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.image-exams-section {
  .exams {
    overflow-y: scroll;
    height: 320px;
  }
}
</style>
