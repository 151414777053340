<template>
  <div>
    <v-row no-gutters>
      <v-col class="font-weight-medium">{{ description }}</v-col>
      <v-col class="text-right" v-show="showControls">
        <v-btn icon @click="prev">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon @click="next">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col>
        <div class="carousel py-2" ref="carousel">
          <slot></slot>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'Carousel',
  props: {
    description: {
      type: String,
      default: '',
    },
    showControls: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    next() {
      const left = this.$refs.carousel.scrollLeft + 370
      this.$refs.carousel.scrollTo({
        left: left,
        behavior: 'smooth',
      })
    },
    prev() {
      const left = this.$refs.carousel.scrollLeft - 370
      this.$refs.carousel.scrollTo({
        left: left,
        behavior: 'smooth',
      })
    },
  },
}
</script>
<style scoped>
.carousel {
  display: flex;
  overflow-x: auto;
}

.carousel::-webkit-scrollbar {
  display: none;
}
</style>
