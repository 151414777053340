<template>
  <div class="calls-section mx-3" v-show="!loading && calls.length > 0">
    <Carousel
      description="Teleatendimentos Agendados"
      :showControls="calls.length > 0"
    >
      <LoadingContainer v-if="loading" />
      <p v-else-if="calls.length == 0">Nenhum teleatendimento encontrado.</p>
      <v-card
        v-else
        min-width="315"
        width="315"
        min-height="280"
        class="mr-4 box"
        outlined
        v-for="(call, index) in calls"
        :key="index"
      >
        <v-list-item three-line class="mx-0 fill-height">
          <v-list-item-content>
            <div
              class="d-flex flex-column justify-space-between fill-height"
              style="width: 100%"
            >
              <div class="top">
                <v-list-item-title
                  class="font-weight-medium mb-4 primary--text"
                >
                  <span>
                    <v-icon color="primary">mdi-calendar</v-icon>
                    {{ call.date }}
                  </span>
                  <span style="float: right; font-weight: 600">
                    {{ call.statusDesc }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="mb-4">
                  <p class="mb-1 font-weight-medium">
                    {{ call.kind }}
                  </p>
                  <p class="mb-0">{{ call.doctor }}</p>
                </v-list-item-subtitle>
                <div class="font-weight-light mb-3" style="line-height: 1.3">
                  <div class="mb-1">
                    Você possui um teleatendimento agendado para
                    {{ call.date }}.
                  </div>
                  <div class="mb-1">
                    Se seu agendamento ainda não foi confirmado você pode
                    fazê-lo clicando em CONFIRMAR.
                  </div>
                  <div class="mb-1">
                    Na hora agendada, clique em INICIAR ATENDIMENTO para iniciar
                    a consulta com o médico.
                  </div>
                </div>
              </div>
              <div class="footer">
                <v-divider />
                <v-card-actions
                  :class="[
                    { 'justify-center': $vuetify.breakpoint.mobile },
                    { 'justify-end': !$vuetify.breakpoint.mobile },
                    'mt-2',
                  ]"
                >
                  <ConfirmDialog
                    v-if="scheduledStatusCode == call.status"
                    @confirmed="() => (call.status = confirmedStatusCode)"
                    :isn="call.id"
                    :branch="call.branch"
                    color="secondary"
                  />
                  <v-btn
                    class="ml-4"
                    @click.prevent="openCall(call)"
                    color="primary"
                  >
                    Iniciar
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </Carousel>
  </div>
</template>
<script>
import Carousel from '@/components/Carousel'
import Api from '@/resources/NajaSocialApi'
import LoadingContainer from '@/components/LoadingContainer'
import ConfirmDialog from './ConfirmDialog'
import CheckinMixin from './CheckinMixin'
import { isEmpty, orderBy } from 'lodash'
import { mapState } from 'vuex'
const STATUS_MAP = {
  canceled: 1, // Cancelado
  scheduled: 2, // Agendado
  confirmed: 3, // Confirmado
  realized: 4, // Realizado    //passa a ser chamado de Atendido na recepção 08/02/2017 (Unificação as agendas)
  pre_scheduled: 5, // Pré-Agendado
  pre_attended: 6, // Pré-Atendido
  waiting_at_reception: 7, // Aguardando na recepção
  in_attendance: 8, // em Atendimento
  attended: 9, // Atendido pelo médico
  awaiting_scheduling: 11, // Aguardando agendamento
}
export default {
  name: 'CallsSection',
  components: { Carousel, LoadingContainer, ConfirmDialog },
  mixins: [CheckinMixin],
  data() {
    return {
      loading: false,
      calls: [],
      scheduledStatusCode: STATUS_MAP.scheduled,
      confirmedStatusCode: STATUS_MAP.confirmed,
    }
  },
  computed: {
    ...mapState({
      user: state => state.data.user,
    }),
  },

  mounted() {
    this.loadData()
  },

  methods: {
    _parseCalls(data) {
      const calls = []
      const permitedStatus = [
        STATUS_MAP.scheduled,
        STATUS_MAP.confirmed,
        STATUS_MAP.in_attendance,
      ]

      const onlySameOrAfterDaySchedules = data.filter(schedule => {
        const date = this.$moment(schedule.data, 'YYYY-MM-DD')
        return date.isSameOrAfter(new Date(), 'day')
      })

      const ordenedSchedules = orderBy(
        onlySameOrAfterDaySchedules,
        item => {
          const hour = item.procedimentos
            .map(pro => {
              return pro.hora_seq
            })
            .sort()[0]
          const dateTime = `${item.data} ${hour}`
          return this.$moment(dateTime, 'YYYY-MM-DD HHmm')
        },
        ['asc']
      ).filter(s => permitedStatus.includes(s.status.codigo))

      ordenedSchedules.forEach(schedule => {
        const hour = schedule.procedimentos
          .map(pro => {
            return pro.hora_seq
          })
          .sort()[0]
        const hourFormated = hour
          ? this.$moment(hour, 'HHmm').format('HH:mm')
          : ''
        const dataFormated = this.$moment(schedule.data, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        )
        const dateHour = hour
          ? `${dataFormated} às ${hourFormated}`
          : dataFormated

        calls.push({
          id: schedule.isn_agendamento,
          date: dateHour,
          hour: hourFormated,
          kind: schedule.procedimentos[0].procedimento.nome,
          doctor: schedule.procedimentos[0].procedimento.medico_agenda.nome,
          url_teleatendimento_paciente: schedule.url_teleatendimento_paciente,
          isn_agendamento: schedule.isn_agendamento,
          checkin_realizado: schedule.checkin_realizado,
          status: schedule.status.codigo,
          statusDesc: schedule.status.nome,
          branch: schedule.empresa.codigo,
        })
      })

      this.calls = calls
    },

    openCall(schedule) {
      const url = schedule.url_teleatendimento_paciente
      if (isEmpty(url)) {
        this.$toast.show('Chamada não disponível', 'error')
      } else {
        window.open(url, '_blank')
      }
    },

    _defaultSearhParams() {
      const today = this.$moment().format('YYYY-MM-DD')
      let params = {
        data_inicio: today,
        data_fim: today,
        somente_teleatendimento: true,
      }

      if (isEmpty(this.user.cpf)) {
        params.prontuario = this.user.username.slice(-8)
      } else {
        params.cpf_paciente = this.$raw(this.user.cpf)
      }
      return params
    },

    loadData() {
      this.loading = true
      const payload = this._defaultSearhParams()
      Api.patientSchedules(payload)
        .then(response => {
          this._parseCalls(response.data.schedules)
        })
        .catch(error => {
          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
