<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="secondary" dark v-bind="attrs" v-on="on"> Confirmar </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Confimar Agendamento</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-card-title>
        <span class="text-h5">Confimar Agendamento</span>
      </v-card-title> -->
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-textarea
                class="mt-4"
                filled
                name="call-schedule-confirmation"
                label="Alguma observação para o agendamento?"
                v-model="form.answer"
                hide-details
                autofocus
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save" :loading="loading">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
export default {
  name: 'HomeConfirmDialog',
  props: ['isn', 'branch'],
  computed: {
    theme() {
      return this.$store.getters.theme
    },
  },

  data() {
    return {
      loading: false,
      dialog: false,
      form: {
        isn: this.isn,
        answer: '',
        branch: this.branch,
      },
    }
  },

  methods: {
    save() {
      this.loading = true
      Api.confirmSchedule(this.form)
        .then(() => {
          this.$toast.show('Agendamento confirmado', 'success')
          this.$emit('confirmed', this.form.isn)
          this.dialog = false
        })
        .catch(error => {
          this.$toast.show('Erro ao confirmar agendamento', 'error')
          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
