<template>
  <div class="calls-section mx-3" v-show="!loading && schedules.length > 0">
    <Carousel
      description="Atendimentos Agendados"
      :showControls="schedules.length > 0"
    >
      <v-card
        min-width="315"
        width="315"
        min-height="290"
        class="mr-4 box"
        outlined
        v-for="(schedule, index) in schedules"
        :key="index"
      >
        <v-list-item three-line class="mx-0 fill-height">
          <v-list-item-content>
            <div class="d-flex flex-column justify-space-between fill-height">
              <div class="top">
                <v-list-item-title
                  class="font-weight-medium mb-4 primary--text"
                >
                  <v-icon color="primary">mdi-calendar</v-icon>
                  {{ schedule.date }}
                </v-list-item-title>
                <v-list-item-subtitle style="-webkit-line-clamp: unset">
                  <p class="mb-2">
                    <v-icon color="primary">mdi-account</v-icon>
                    Médico:
                    <span class="font-weight-medium">{{
                      schedule.doctor
                    }}</span>
                  </p>
                  <div v-if="schedule.address != null" class="mb-2">
                    <v-icon color="primary">mdi-map-marker</v-icon>
                    <span>{{ schedule.address }}</span>
                  </div>
                </v-list-item-subtitle>
                <div
                  class="items"
                  style="height: 130px; overflow-y: auto; padding-right: 5px"
                >
                  <v-alert
                    v-for="(item, index) in schedule.items"
                    class="mt-2"
                    color="#DEDEDE"
                    dense
                    :key="`item-${index}`"
                  >
                    <span
                      class="font-14 font-weight-medium"
                      style="color: #384048"
                    >
                      {{ item.procedimento.nome }}
                    </span>
                  </v-alert>
                </div>
              </div>
              <div class="footer">
                <v-divider />
                <v-card-actions class="justify-end mt-2">
                  <ConfirmDialog
                    v-if="scheduledStatusCode == schedule.status"
                    @confirmed="() => (schedule.status = confirmedStatusCode)"
                    :isn="schedule.id"
                    :branch="schedule.branch"
                  />
                  <v-btn
                    color="primary"
                    class="ml-2"
                    @click.prevent="openCheckin(schedule)"
                  >
                    Checkin
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </Carousel>
  </div>
</template>
<script>
import Carousel from '@/components/Carousel'
import Api from '@/resources/NajaSocialApi'
import ConfirmDialog from './ConfirmDialog'
import CheckinMixin from './CheckinMixin'
import { isEmpty, orderBy } from 'lodash'
import { mapState } from 'vuex'
const STATUS_MAP = {
  canceled: '1', // Cancelado
  scheduled: '2', // Agendado
  confirmed: '3', // Confirmado
  realized: '4', // Realizado    //passa a ser chamado de Atendido na recepção 08/02/2017 (Unificação as agendas)
  pre_scheduled: '5', // Pré-Agendado
  pre_attended: '6', // Pré-Atendido
  waiting_at_reception: '7', // Aguardando na recepção
  in_attendance: '8', // em Atendimento
  attended: '9', // Atendido pelo médico
  awaiting_scheduling: '11', // Aguardando agendamento
}
export default {
  name: 'SchedulesSection',
  components: { Carousel, ConfirmDialog },
  mixins: [CheckinMixin],
  data() {
    return {
      schedules: [],
      loading: false,
      scheduledStatusCode: STATUS_MAP.scheduled,
      confirmedStatusCode: STATUS_MAP.confirmed,
    }
  },

  mounted() {
    this.loadData()
  },

  computed: {
    ...mapState({
      user: state => state.data.user,
    }),
  },

  methods: {
    init() {},

    _parseSchedules(data) {
      const schedules = []

      const ordenedSchedules = orderBy(
        data,
        item => {
          const hour = item.procedimentos
            .map(pro => {
              return pro.hora_seq
            })
            .sort()[0]
          const dateTime = `${item.data} ${hour}`
          return this.$moment(dateTime, 'YYYY-MM-DD HHmm')
        },
        ['asc']
      )

      ordenedSchedules.forEach(schedule => {
        const hour = schedule.procedimentos
          .map(pro => {
            return pro.hora_seq
          })
          .sort()[0]
        const dataFormated = this.$moment(schedule.data, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        )
        const dateHour = hour
          ? `${dataFormated} às ${this.$moment(hour, 'HHmm').format('HH:mm')}`
          : dataFormated

        schedules.push({
          id: schedule.isn_agendamento,
          date: dateHour,
          address: null,
          status: schedule.status.codigo,
          branch: schedule.empresa.codigo,
          checkin_realizado: schedule.checkin_realizado,
          kind: schedule.procedimentos[0].procedimento.nome,
          items: schedule.procedimentos,
          doctor: schedule.procedimentos[0].procedimento.medico_agenda.nome,
        })
      })

      this.schedules = schedules
    },

    _defaultSearhParams() {
      const startDate = this.$moment().format('YYYY-MM-DD')
      const endDate = this.$moment().add(1, 'M').format('YYYY-MM-DD')

      let params = {
        data_inicio: startDate,
        data_fim: endDate,
        somente_teleatendimento: false,
      }

      if (isEmpty(this.user.cpf)) {
        params.prontuario = this.user.username.slice(-8)
      } else {
        params.cpf_paciente = this.$raw(this.user.cpf)
      }
      return params
    },

    loadData() {
      this.loading = true
      const payload = this._defaultSearhParams()

      Api.patientSchedules(payload)
        .then(response => {
          this._parseSchedules(response.data.schedules)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
