<template>
  <div class="home">
    <v-row>
      <v-col>
        <PatientCard />
      </v-col>
      <v-col v-if="scheduleVersion != null">
        <div class="d-flex py-4 justify-end">
          <v-card
            max-width="160"
            outlined
            elevation="2"
            v-show="examScheduleEnabled"
            class="mr-4 card-action"
          >
            <v-list-item
              three-line
              class="mx-auto"
              :href="
                scheduleVersion == '1.0'
                  ? '/portal/schedule'
                  : getNajaURL('schedule_exams')
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  style="white-space: normal"
                  class="primary--text font-weight-medium"
                >
                  <div class="d-flex justify-center align-center">
                    <v-icon size="25" class="mr-2" color="primary">
                      mdi-heart-pulse
                    </v-icon>
                    Agendar Exame
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card
            max-width="160"
            outlined
            elevation="2"
            color="primary"
            v-show="appointmentScheduleEnabled"
            class="card-action"
          >
            <v-list-item
              three-line
              class="mx-auto"
              :href="
                scheduleVersion == '1.0'
                  ? '/portal/schedule'
                  : getNajaURL('schedule_appointments')
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  style="white-space: normal"
                  class="primary--text font-weight-medium"
                >
                  <div class="d-flex justify-center white--text align-center">
                    <v-icon size="25" class="mr-2" color="white">
                      mdi-medical-bag
                    </v-icon>
                    Agendar Consulta
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="portalHomeTip">
      <v-col>
        <Tip>
          <div v-html="portalHomeTip"></div>
        </Tip>
      </v-col>
    </v-row>
    <CallsSection class="mt-8" v-if="allowsRemoteCalls" />
    <SchedulesSection class="mt-8" />
    <ImageExamsSection class="mt-8" />
    <LabExamsSection class="mt-8" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import PatientCard from './widgets/PatientCard.vue'
import CallsSection from './widgets/CallsSection.vue'
import SchedulesSection from './widgets/SchedulesSection.vue'
import ImageExamsSection from './widgets/ImageExamsSection.vue'
import LabExamsSection from './widgets/LabExamsSection.vue'
import Api from '@/resources/NajaSocialApi'
import Tip from '@/views/schedule/widgets/tip'
export default {
  name: 'Home',
  components: {
    PatientCard,
    CallsSection,
    SchedulesSection,
    ImageExamsSection,
    LabExamsSection,
    Tip,
  },

  computed: {
    ...mapState({
      scheduleVersion: state => state.data.organization.schedule_version,
      examScheduleEnabled: state =>
        state.schedule.options.exam_schedule_enabled,
      appointmentScheduleEnabled: state =>
        state.schedule.options.appointment_schedule_enabled,
      allowsRemoteCalls: state => state.data.organization.allows_remote_calls,
      portalHomeTip: state => state.data.organization.portal_home_tip,
    }),

    // scheduleLink() {
    //   this.scheduleVersion == '1.0' ? '/schedule' : `${Api.najaBaseURL()}`
    //   const external = this.$store.state.data.organization.external_report
    //   return external != null && external != ''
    // },
  },

  methods: {
    getNajaURL(path) {
      console.log(`${Api.najaBaseURL}/${path}`)
      return `${Api.najaBaseURL}/${path}`
    },
  },

  created() {
    this.$store.dispatch('schedule/getOptions')
  },
}
</script>
<style scoped>
.card-action {
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
</style>
