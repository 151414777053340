<template>
  <v-card max-width="344" outlined color="rgba(0, 0, 0, 0)">
    <v-list-item three-line class="mx-0 px-0">
      <v-list-item-avatar tile size="80">
        <v-img v-if="user.avatar" :src="avatarSrc()"></v-img>
        <v-icon v-else size="80"> mdi-account-circle </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium mb-4">
          Seja bem vindo(a)!
        </v-list-item-title>
        <v-list-item-subtitle>
          <p class="mb-2"><strong>Nome: </strong> {{ user.name }}</p>
          <p>
            <strong>Data Nasc:</strong>
            {{ patient.data_nascimento | birthday }}
          </p>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import Api from '@/resources/NajaSocialApi'
import moment from 'moment'
export default {
  name: 'PatientCard',
  computed: {
    ...mapState({
      user: state => state.data.user,
    }),
  },

  data() {
    return {
      loading: false,
      patient: {},
    }
  },

  mounted() {
    if (this.user.role == 'patient') {
      this.getPatient()
    }
  },

  filters: {
    birthday: function (value) {
      if (!value) return ''
      return moment(value, 'YYYYMMDD').format('DD/MM/YYYY')
    },
  },

  methods: {
    getPatient() {
      this.loading = true
      const patient = this.user.username.slice(-8)
      Api.patient(patient)
        .then(response => {
          this.patient = response.data.patient
        })
        .catch(error => {
          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
