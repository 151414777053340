<template>
  <div class="image-exams-section px-3" v-show="!loading && exams.length > 0">
    <v-card outlined class="box">
      <div class="pa-4">
        <v-row>
          <v-col>
            <p class="ma-0">
              <v-icon color="primary">mdi-seat-flat-angled</v-icon>
              <span class="ml-2 font-weight-bold text-uppercase primary--text">
                Últimos Exames de Imagens
              </span>
            </p>
          </v-col>
          <v-col class="text-right">
            <v-btn small outlined color="primary" :to="'/exams?tab=images'">
              Lista completa
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-divider />
      <LoadingContainer v-if="loading" />
      <p v-else-if="exams.length == 0">Nenhum exame encontrado.</p>
      <div v-else class="exams">
        <div v-for="(exam, index) in exams" :key="index" class="px-4 py-2">
          <div class="pa-4">
            <v-row no-gutters class="link-color">
              <v-col cols="12" sm="2">
                <p class="font-weight-medium">
                  <v-icon color="primary">mdi-calendar</v-icon>
                  {{ exam.date | njDate }}
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <p>
                  <v-icon color="primary" class="mr-1">mdi-account</v-icon>
                  Solicitante: <strong>{{ exam.doctor_requestor }}</strong>
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <p class="font-weight-medium">
                  {{ exam.name }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-right">
                <ReportDialog :lau-isn="exam.lau_isn" :pad-isn="exam.id">
                  <template v-slot:open="{ open }">
                    <v-btn
                      @click="open"
                      text
                      class="text-capitalize font-weight-regular"
                    >
                      <v-icon left color="primary"> mdi-clipboard-text </v-icon>
                      <span class="link-color">Ver Laudo</span>
                    </v-btn>
                  </template>
                </ReportDialog>
                <v-btn
                  text
                  class="link-color text-capitalize font-weight-regular"
                  @click.prevent="openPACS(exam)"
                >
                  <v-icon left color="primary"> mdi-image </v-icon>
                  <span class="link-color">Ver Imagens</span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-divider v-if="index < exams.length - 1" />
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import LoadingContainer from '@/components/LoadingContainer'
import ReportDialog from '@/components/ReportDialog'
import { orderBy } from 'lodash'

export default {
  name: 'ImageExamSections',
  components: { LoadingContainer, ReportDialog },
  data() {
    return {
      loading: false,
      loadingPACS: false,
      exams: [],
    }
  },

  mounted() {
    this.getExams()
  },

  methods: {
    init() {},

    openPACS(exam) {
      if (this.loadingPACS) {
        this.$toast.show('PACS carregando ...', 'warn')
        return
      }

      this.loadingPACS = true

      const params = {
        pad_isn: this.$hashids.encode(exam.origin.pad_isn),
        pac_cod: exam.origin.pac_cod,
        emp_sigla: exam.origin.emp_sigla,
        pex_data: exam.origin.pex_data,
        sgr_codmoddicom: exam.origin.sgr_codmoddicom,
        emp_cod: exam.origin.ate_emp_cod,
        pac_datanasc: exam.origin.pac_datanasc,
        pac_nome: exam.origin.pac_nome,
      }

      const windowReference = window.open()

      Api.pacs(params)
        .then(response => {
          const url = response.data.url
          if (url !== null && url !== '' && url !== undefined) {
            windowReference.location = response.data.url
          } else {
            this.$toast.show('PACS - URL inválida', 'error')
          }
        })
        .catch(error => {
          this.$toast.show('Erro ao carregar PACS', 'error')
          throw error
        })
        .finally(() => {
          this.loadingPACS = false
        })
    },

    async getExams() {
      this.loading = true
      try {
        const imageExamsResponse = await Api.imageExams({})
        this.exams = orderBy(
          imageExamsResponse.data.exams,
          exam => {
            return this.$moment(exam.date)
          },
          ['desc']
        ).slice(0, 6)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.image-exams-section {
  .exams {
    overflow-y: scroll;
    height: 320px;
  }
}
</style>
