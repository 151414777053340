import { isEmpty } from 'lodash'
import Api from '@/resources/NajaSocialApi'

const mixin = {
  methods: {
    async _toCheckin(isn, patientBirthday) {
      const user = this.$store.state.data.user
      const payload = {}

      if (isEmpty(user.cpf)) {
        payload.prontuario = user.username.slice(-8)
      } else {
        payload.cpf = this.$raw(user.cpf)
      }

      if (patientBirthday) {
        payload.data_nasc = this.$moment(patientBirthday, 'DD/MM/YYYY').format(
          'YYYYMMDD'
        )
      }

      try {
        await this.$store.dispatch('checkin/loadSchedules', payload)
      } catch (e) {
        this.$toast.show('Erro ao buscar dados do checkin', 'warn')
        throw e
      }

      if (
        this.$store.state.checkin.schedules.findIndex(s => s.AEX_Isn == isn) < 0
      ) {
        this.$toast.show('Erro ao buscar agendamentos', 'warn')
      } else {
        this.$store.commit('checkin/updateForm', { step: 1 })
        this.$router.push(`/checkin?schedule=${isn}`)
      }
    },

    async openCheckin(call) {
      if (call.checkin_realizado) {
        this.$toast.show('Checkin já realizado para este agendamento', 'warn')
      } else {
        try {
          const payload = this._defaultSearhParams()
          payload.isn_agendamento = call.id
          const response = await Api.patientSchedules(payload)
          const schedule = response.data.schedules[0]

          if (schedule.checkin_realizado) {
            this.$toast.show(
              'Checkin já realizado para este agendamento',
              'warn'
            )
          } else {
            this._toCheckin(
              schedule.isn_agendamento,
              schedule.paciente.data_nascimento
            )
          }
        } catch (e) {
          this.$toast.show('Erro ao carregar agendamento', 'warn')
          throw e
        }
      }
    },
  },
}

export default mixin
